.banner {
    background-color: #003470; 
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
  }

  .card-image {
    width: 100%;
    height: auto; 
  }
  
  .banner-text {
    font-size: 1.5em; 
  }
  
  .banner-button {
    background-color: #003aaf; 
    border: none;
    padding: 10px 20px;
    color: white;
    cursor: pointer;
    font-size: 1em;
    border-radius: 5px; 
    transition: background-color 0.3s; 
  }
  
  .banner-button:hover {
    background-color: #002574; 
  }

  .textbanner-container {
    width: 100%;
    background-color: #ffffff; 
    color: #000000; 
    padding: 20px; 
    box-sizing: border-box;
    align-items: center;
  }
  
  .textbanner-title {
    font-size: 24px; 
    margin-bottom: 10px; 
    text-align: center;
  }
  
  .textbanner-content {
    font-size: 17px; 
    margin-left: 40px;
    text-align: justify;
  }

  .accordion-item {
    border-bottom: 1px solid #ccc;
    padding: 10px;
  }
  
  .accordion-title {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .accordion-content {
    margin-top: 10px;
  }

  .content-block {
    margin: 20px; 
    padding: 20px;
  }
  
  .content-title {
    font-size: 1.5rem; 
    margin-bottom: 1rem; 
  }
  
  .content-text {
    font-size: 1rem; 
  }

.hero-section {
  position: sticky;
  background-size: cover;
  background-position: center;
  height: 80vh; 
  display: grid;
  align-items: center; 
  text-align: center;
  color: white;
  text-shadow: 0 2px 4px rgba(0,0,0,0.3); 
  border-image: 20%;
  z-index: 1;
  top: 0;
}

.hero-content {
  z-index: 2;
  max-width: 100%; 
  background-color: rgba(0, 0, 0, 0.7);
  height: 100%;
}

.hero-content h1 {
  font-size: 2.5rem;
  padding-top: 15rem;
  border-left: inherit;
}

.hero-content p {
  margin-top: 1rem;
  font-size: 1.5rem; 
  padding-left: 10rem;
  padding-right: 10rem;
}

.hero-content button {
  margin-top: 1.5rem;
  padding: 0.5rem 1rem;
  background-color: #000; 
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.hero-content button:hover {
  background-color: #555; 
}

.titleProduct {
  font-size: 2.5rem; 
  padding-top: 15rem;
  border-left: inherit;
}


h2, h3, h4, h5, h6, .h7, .h8, .h9, .h10 {
  font-size: 24px;
  font-weight: bold;
  padding-left: 15px;
  border-left: 5px solid #003470;
  display: inline-block;
  margin-left: 20px;
}

.features-container {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  align-items: stretch;
  justify-content: stretch;
  padding-left: 25px;
  padding-right: 25px;
}

.feature {
  width: calc(50% - 25px); 
  min-width: auto;
  min-height: auto;
  box-sizing: border-box;
  order: 0;
  background-color: #ffffff; 
  box-shadow: 0 2px 5px rgba(0,0,0,0.5);
  cursor: pointer;
  padding-top: 2rem !important;
  padding-right: 2rem !important;
  padding-bottom: 2rem !important;
  padding-left: 2rem !important;

}

.feature h2 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #333;
}

.feature p {
  font-size: 1rem;
  line-height: 1.6;
  color: #666;
  text-align: justify;
}

.feature a {
  display: inline-block;
  color: #0056b3; 
  text-decoration: none; 
  padding: 5px 0; 
}

.feature:hover {
  transform: scale(1.05);
  z-index: 20;
}

.feature p :hover {
  transform: inherit;
}

@media (max-width: 768px) {
  .features-container {
    flex-direction: column;
  }

  .feature {
    width: 100%; 
    margin-bottom: 20px; 
  }
}

.imgbanner-container2 {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 20px;
}

.imgbanner-title, .textbanner-content {
  margin-right: 20px;
}

.imgcard-image {
  max-width: 30%;
  height: auto;
  margin-left: 10%;
  margin-right: 10%;
}