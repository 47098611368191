/* Style header general et logo company */

.header {
  display: flex;
  justify-content: space-between;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Ombre portée */
  align-items: center;
  background: black;
  padding: 10px 20px;
  color: #fff;
  width: 100%;
  height: 100%;
}

.CompanyLogo {
  width: 80px;
  height: auto;
  margin-left: 100px;
}

/* Style de base pour les liens de navigation */
.main-navigation {
  display: flex;
  justify-content: space-between;
  /* Espace entre les éléments */
  align-items: center;
  /* Centrer les éléments verticalement */
  list-style-type: none;
  padding: 0 20px;
  /* Padding sur les côtés */
  height: 50px;
  position: relative;
  /* Ajouté */
  z-index: 1000;
}

.main-navigation ul {
  list-style-type: none;
  /* Enlève les puces de la liste */
  padding: 0;
  /* Enlève le padding par défaut */
  margin: 0;
  /* Enlève la marge par défaut */
  display: flex;
  /* Alignement horizontal des éléments de liste */
  height: 100%;
  /* Assure que les liens s'étendent à la hauteur de .main-navigation */
}

.nav-section {
  position: relative;
  align-items: center;
  /* Centre les éléments verticalement */
  height: 100%;
}

.nav-section a {
  color: #d3d3d3;
  /* Couleur gris clair pour le texte non cliqué */
  display: block;
  /* Assure que le lien remplit .nav-section */
  padding: 0 15px;
  /* Espacement horizontal */
  text-decoration: none;
  /* Enlève le soulignement */
  line-height: 50px;
  /* Hauteur de ligne correspondant à .main-navigation */
  border-bottom: 3px solid transparent;
  /* Préparation pour le soulignement */
  position: relative;
  font-size: 16px;
}


.hamburger-menu {
  display: none; /* initialement caché */
}

.nav-section a.this-page {
  border-bottom: 3px solid #ffffff;
  z-index: 100;
}

.nav-section a:hover {
  color: #ffffff;
  /* Texte en blanc au survol */
  border-bottom: 4px solid #0046a9;
  /* Trait gris clair au survol */
}

.this-page {
  margin-bottom: -3px;
  /* Ajuste cela selon les besoins réels de ton CSS */
}

.dropdown-content {
  display: none;
  position: absolute;
  min-width: 160px;
  background-color: #ffffff;
  z-index: 100;
  left: 0;
  white-space: nowrap;
  padding: 10px;
}

.nav-section:hover .dropdown-content {
  display: block;
  z-index: 100;
  /* flex-direction: column;
  align-items: flex-start;  */
}

.dropdown-section {
  padding: 0 10px;
  /* Espace entre les colonnes */
  /* Assure-toi que le contenu de la section ne dépasse pas de la largeur disponible */
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Style des titres de catégorie */
.dropdown-section h4 {
  margin-bottom: 0.5rem;
  color: #333;
  /* Couleur du texte pour les titres */
  font-size: 1rem;
  /* Taille du texte pour les titres */
}

.dropdown-content a {
  color: black;
  text-decoration: none;
  display: block;
  /* S'assure que le lien prend toute la largeur de la colonne */
  padding-top: 2px;
  /* Ajustez la valeur supérieure selon vos besoins */
  padding-bottom: 2px;
  line-height: 1.2;
}

.dropdown-content a:hover {
  background-color: transparent !important;
  /* Supprime la couleur de fond au survol */
  color: #000000 !important;
  /* Couleur gris clair pour le texte non cliqué */
  border-bottom: none !important;
  text-decoration: underline !important;
}

.icon-section {
  display: flex;
  align-items: center;
  margin-right: 10%;
  /* Ajoute d'autres styles ici si nécessaire */
}

.icon {
  width: 24px;
  /* Largeur de l'icône */
  height: auto;
  /* Hauteur auto pour conserver le ratio */
  margin-right: 10px;
  /* Espacement entre les icônes */
}

.flag {
  width: 31px;
  /* ou la largeur que vous préférez */
  height: 31px;
  /* ou la hauteur pour maintenir le ratio si nécessaire */
  border-radius: 50%;
  /* Cela crée un cercle parfait si l'image est carrée */
  overflow: hidden;
}


/* Pour la barre de recherche étendue */
.search-expanded {
  display: flex;
  align-items: right;
  justify-content: space-between;
  flex-grow: 1;
  padding: 0 1rem;
  /* Ajuste le padding selon tes préférences */
  margin-left: 30rem;
  /* Ajuste la marge pour aligner avec les sections */
}

.search-expanded input[type="text"] {
  width: 50%;
  padding: 0.5rem;
  border: 2px solid #003f83;
  /* Bordure bleue, tu peux ajuster la couleur */
  border-radius: 15px;
  /* Arrondir les coins */
}

/* Bouton de fermeture */
.search-close {
  background: none;
  /* Fond bleu, ajuste selon tes préférences */
  color: white;
  /* Texte en blanc */
  border: none;
  /* Pas de bordure */
  padding: 0.5rem;
  /* Padding similaire à l'input */
  border-radius: 4px;
  /* Arrondir les coins */
  cursor: pointer;
  /* Style de curseur pour indiquer l'action */
}


.login-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* Fond semi-transparent */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.login-popup-content {
  background: white;
  padding: 20px;
  width: 400px;
  /* ou la largeur que vous souhaitez */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  border-radius: 5px;
}

.login-popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  font-size: 1.5rem;
}

h2 {
  color: #333;
  /* Couleur du texte */
  margin-bottom: 20px;
}

input[type="email"] {
  width: calc(100% - 100px);
  /* Ajustez en fonction de la largeur du bouton */
  padding: 10px;
  margin-right: 10px;
  border: 2px solid #007bff;
  /* Bordure du champ de saisie */
  border-radius: 4px;
  /* Coins arrondis pour le champ de saisie */
}

.login-popup-submit {
  background-color: #0A6ED1;
  /* Couleur SAP */
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 10px;
  border-radius: 4px;
}

.main-navigation.show {
  display: block;
}

.main-navigation.hide {
  display: none;
}

@media (max-width: 768px) {
  .CompanyLogo {
    width: 50px;
    margin-left: 10px;
    /* Réduit la taille du logo */
  }

  .main-navigation {
    display: none;
    /* Masque la navigation principale */
  }

  .hamburger-menu {
    display: block;
    /* Affiche le menu hamburger */
  }

  .icon {
    display: none;
  }

  .burger {
    width: 20px;
  }

  .flag {
    display: none;
  }

  .header {
    position: relative; /* Assure que le positionnement absolu de .main-navigation est relatif à .header */
  }

  .hamburger-menu {
    display: block; /* Affiche l'icône hamburger */
    cursor: pointer;
    z-index: 101; /* Assure que l'icône hamburger est au-dessus du menu */
  }

  .main-navigation {
    display: none; /* Cache la navigation jusqu'à ce qu'elle soit activée */
    position: absolute; /* Positionne le menu par rapport à son parent positionné (.header) */
    top: 100%; /* Positionne le menu juste en dessous du header */
    left: 0;
    right: 0;
    
    flex-direction: column; /* Empile les éléments de la liste verticalement */
    z-index: 100; /* Assure que le menu s'affiche au-dessus des autres contenus */
    border-top: 1px solid #ccc; /* Ajoute une ligne en haut du menu */
  }

  .main-navigation.show {
    display: block; /* Affiche le menu quand 'show' est ajouté à la classe */
  }

  .main-navigation ul {
    display: block; /* Ceci assure que la liste est traitée comme un bloc, ce qui permet aux liens de s'empiler verticalement */
    padding: 0; /* Enlève tout padding intérieur qui pourrait être hérité */
  }

  .main-navigation ul li {
    display: block; /* Chaque lien est affiché comme un bloc, s'empilant verticalement */
    border-bottom: 1px solid #ccc; /* Ajoute une séparation visuelle entre les liens */
    background-color: white; /* Fond blanc pour chaque lien */
  }

  .main-navigation ul li a {
    color: black; /* Texte en noir */
    padding: 15px; /* Ajoute de l'espace autour du texte */
    display: block; /* Les liens remplissent leur conteneur li */
    text-decoration: none; /* Enlève le soulignement */
    transition: background-color 0.3s ease; /* Animation pour le changement de fond */
  }

  .main-navigation ul li a:hover, .main-navigation ul li a:active {
    background-color: darkblue; /* Change le fond au clic ou au survol */
    color: white; /* Change la couleur du texte en blanc */
  }
}
