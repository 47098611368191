.subscription-section {
  text-align: center;
  margin-bottom: 30px;
}

.subscription-header p {
  color: #000000;
  font-size: 21px;
  margin: 20px auto;
  width: 80%;
  line-height: 1.6;
}

.monthly-price,
.trial-period {
  color: #0078d7;
  cursor: pointer;
  text-decoration: underline;
  margin: 10px 0;
}

.monthly-price:hover,
.trial-period:hover {
  text-decoration: none;
}

.subscription-container {
  display: flex;
  flex-direction: row;
  /* les cartes seront côte à côte */

  gap: 20px;
  /* espace entre les cartes */
  flex-wrap: wrap;
  /* permet aux cartes de passer à la ligne suivante si nécessaire */
  justify-content: space-between;
  margin-left: 100px;
  margin-right: 100px;
  text-align: left;
}

.product-card {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  width: 300px;
  /* largeur fixe pour chaque carte */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  /* éléments de la carte en colonne */
}

.product-header,
.product-footer {
  text-align: left;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 20px;
}

.features-list {
  list-style: inside;
  /* enlève les puces */
  padding: 0;
  margin: 10px;
  flex-grow: 1;
  /* la liste prend tout l'espace disponible */
  text-align: left;
}

.features-list li {
  margin-bottom: 10px;
  /* espace entre les fonctionnalités */
}

.product-card button {
  background-color: #0078d7;
  color: white;
  border: none;
  font-size: 17px;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  margin-top: auto;
  /* aligne le bouton en bas de la carte */
}

.product-card button:hover {
  background-color: #005a9e;
}