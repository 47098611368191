.language-selector {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .selected-language {
    display: flex;
    align-items: center;
  }
  
  .language-dropdown {
    padding: 5px;
    font-size: 16px;
    margin-left: 10px;
    border-radius: 4px;
    border: 1px solid #ddd;
    cursor: pointer;
  }
  
  .language-dropdown option {
    padding: 5px;
  }
  