.roadmap-container {
  max-width: 900px;
  margin: 50px auto;
  padding: 20px;
  text-align: center;
  font-family: 'Arial', sans-serif;
}

.idea-form-container {
  background: #f4f4f4;
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
}

.idea-form-container h2 {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.idea-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.idea-input, .idea-textarea {
  width: 100%;
  padding: 12px;
  border: 2px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

.idea-submit-btn {
  background: linear-gradient(to right, #6a11cb, #2575fc);
  color: white;
  border: none;
  padding: 15px;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
}

.idea-message {
  margin-top: 20px;
  font-size: 16px;
  color: green;
}

.ideas-list {
  margin-top: 40px;
}

.idea-item {
  text-align: left;
  background: #fff;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
}

@media (max-width: 768px) {
  .roadmap-container {
    width: 95%;
  }
}

.idea-card {
  border: 1px solid #ddd;
  padding: 15px;
  margin: 10px 0;
  border-radius: 8px;
  background: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.idea-card h3 {
  margin: 0;
  font-size: 1.5rem;
}

.idea-card p {
  margin: 5px 0;
}

.vote-buttons button {
  font-size: 1.5rem;
  margin: 5px;
  cursor: pointer;
  border: none;
  background: none;
}

.vote-up {
  color: green;
}

.vote-down {
  color: red;
}

.idea-footer {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.idea-status {
  font-weight: bold;
  font-size: 0.9rem;
  text-transform: uppercase;
}