.encart-container {
  position: relative;
  text-align: center;
  color: white; 
  margin-bottom: -3px;
}

.card-image {
  width: 100%;
  height: auto;
  object-fit: cover; 
}

.encart-text {
  position: absolute;
  width: 100%;
  top: 0%; 
  background-color: rgba(0, 0, 0, 0.7); 
  height: 100%;
  box-sizing: border-box;
}

.h1, .encart-text h1 {
  padding-top: 20%;
  font-size: 26px;
  letter-spacing: 0.1em;
  color: white;
  text-transform: uppercase;
  padding-left: 10%;
  padding-right: 10%;
}

.encart-text p {
  font-size: 20px;
  padding-left: 10%;
  padding-right: 10%;
  line-height: 1.5;
  align-items: center;
  font-weight: bold;
}

.title-underline {
  width: 60px;
  height: 4px;
  background-color: #ffffff; 
  margin: 16px auto 16px; 
}

@media screen and (max-width: 800px) {
  .h1, .encart-text h1 {
    padding-top: inherit;
    font-size: 14px;
  }

  .encart-text h2 h4 {
    font-size: 10px; 
  }

  .encart-text p {
    font-size: 10px;
  }
}