.page-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  position: relative;
  font-size: 16px; 
}

h2, h3, h4, h5, h6 {
  font-size: 24px;
  font-weight: bold;
  padding-left: 15px;
  border-left: 5px solid #003470;
  display: inline-block;
  margin-left: 20px;
  line-height: 1.5;
}

.header-content {
  max-width: 100%; 
  padding: 25px;
  order: 2; 
}

.header-image {
  width: 30%; 
  order: 1; 
}

@media screen and (max-width: 768px) {
  .page-header {
    flex-direction: column-reverse;
  }
  
  .header-content {
    max-width: 100%; 
    order: unset; 
  }

  .header-image {
    width: 100%; 
    order: unset; 
  }
}

@media (max-width: 767px) {
  .About-button {
    position: static; 
    margin-top: 20px; 
    text-align: center; 
  }
}

@media (max-width: 767px) {
  .advantages-container {
    flex-direction: column; 
    align-items: center; 
  }

  .advantage-item {
    margin: 20px; 
  }
}

@media (max-width: 767px) {
  .dashboard {
    max-width: 100%; 
    margin-left: 0; 
    margin-right: 0; 
  }

  .dashboard-item {
    border-bottom: none; 
    padding: 10px 0; 
  }

  .dashboard-icon {
    float: none; 
    margin-right: 0;
    width: 100%;
  }
}

.advantages-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #000000; 
  padding: 20px; 
}

.advantage-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 50px; 
}

.advantage-icon {
  margin-bottom: 10px; 
  width: 25%;
}

.advantage-title {
  font-size: 1.5em; 
  color: #ffffff; 
  margin-bottom: 5px; 
}

.advantage-description {
  font-size: 20px; 
  color: white; 
}

.dashboard {
  display: flex;
  flex-direction: column;
  max-width: 70%;
  margin-left: 20px; 
  margin-right: 20px; 
}

.dashboard-item {
  border-bottom: 1px solid #eaeaea; 
  padding: 20px 0;
}

.dashboard-icon {
  float: left;
  margin-right: 10px;
  width: 15%;
}

.dashboard-title {
  font-size: 24px;
  color: #000;
  margin: 0 0 10px 0;
}

.dashboard-description {
  font-size: 22px;
  color: #666;
  margin: 0;
  text-align: justify;
}

.dashboard-button {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 15px;
  background-color: #0056b3;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
}

.dashboard-item::after {
  content: "";
  display: table;
  clear: both;
}
