.financial-module {
  display: flex;
  font-family: 'Arial', sans-serif;
  color: #333;
  margin-left: 25px;
  margin-right: 25px;
  padding-left: 25px;
  padding-right: 25px;
  margin-top: 3.25rem;
  margin-bottom: 5.25rem;
}

.sidebar {
  width: 120%;
  padding: 20px;

  padding-bottom: 3.25rem;
  padding-top: 5.9rem;
}

.sidebar-item {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  width: 100%;
  padding: 10px 0;
  font-size: 24px;
  cursor: pointer;
  border-left: 4px solid transparent;
  color: #333;
  border-bottom: 1px solid #e0e0e0;
}

.sidebar-item.active {
  background-color: #fff;
  font-weight: bold;
  color: black;
}

.sidebarimg {
  width: 60px;
  margin-right: 5px;
}

.ContentIcon {
  max-height: 50px;
  vertical-align: middle;
  margin-right: 10px;
}

.content {
  flex-grow: 1;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-left: 2px;
}

.SubContent {
  display: flex;
  align-items: start;
}

@media (max-width: 768px) {
  .SubContent {
    flex-direction: column;
  }
}

.content h4 {
  color: #ffc902;
  font-size: 40px;
}

.content p {
  font-size: 21px;
  margin-bottom: 20px;
  text-align: justify;
}

.content ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 20px;
}

.content ul li {
  margin-bottom: 10px;
  font-size: 20px;
  line-height: 1.6;
  font-weight: bold;
  margin-left: 3rem;
}

.content ul li:before {
  content: '✔';
  color: #ffc902;
  margin-right: 5px;
}

.images-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 60%;
  margin-left: 10%;
}

.images-container img {
  width: 100%;
  height: auto;
}

.section-button {
  display: block;
  width: auto;
  padding: 10px 15px;
  margin-top: 20px;
  margin-left: auto;
  color: #007bff;
  background-color: transparent;
  border: 2px solid #007bff;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  transition: all 0.3s ease;
}

.section-button:hover {
  background-color: #007bff;
  color: white;
}

@media screen and (max-width: 767px) {
  .financial-module {
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 1rem;
    margin-bottom: 2rem;
    flex-direction: column;
    align-items: center;
  }

  .sidebar {
    width: 100%;
    padding: 10px;
    padding-bottom: 2rem;
    padding-top: 2rem;
  }

  .content {
    margin-left: 0;
  }

  .images-container {
    width: 100%;
    margin-left: 0;
  }
}