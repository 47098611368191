.mon-carrousel-container {
  font-family: 'Arial', sans-serif;
  width: 100%;
  margin: auto;
  position: relative;
  background-color: black;
  justify-content: center;
  flex-direction: column;
  padding: 84px 0;
}

.mon-carrousel-container h2 {
  text-align: center;
  color: #ffffff;
  /* Change cette couleur pour qu'elle corresponde à ton design */
  margin-top: 0px;
  font-size: 34.5px;
  margin-bottom: 60px;
  line-height: 1.17;
  letter-spacing: 1.38px;
}

.mon-carrousel {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  /* Empêche les éléments de se replier sur la ligne suivante */
  overflow-x: auto;
  /* Permet le défilement horizontal */
  scroll-behavior: smooth;
  /* Assure un défilement fluide */
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.mon-carrousel::-webkit-scrollbar {
  display: none;
}

.mon-carrousel-item {
  flex: 0 0 auto;
  /* Ne pas grandir, ne pas rétrécir, base auto */
  width: 300px;
  /* La largeur de chaque élément du carrousel */
  margin-right: 100px;
  /* Espace entre les éléments */
  cursor: pointer;
  /* Indique que l'élément est cliquable */
  scroll-snap-align: start;
  transition: transform 0.3s ease;
}

.mon-carrousel-item:last-child {
  margin-right: 0;
  /* Pas de marge à droite pour le dernier élément */
}

.mon-carrousel-item img {
  width: 100%;
  /* L'image prendra la largeur de .mon-carrousel-item */
  height: auto;
  /* Pour maintenir l'aspect ratio de l'image */
}

.mon-carrousel-item h3 {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  text-align: center;
  height: 45px;
}


.mon-carrousel-controls {
  position: absolute;
  /* Position des boutons */
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}

.mon-carrousel-controls button {
  border: none;
  background-color: #fff;
  /* Change la couleur de fond si nécessaire */
  color: #333;
  /* Couleur du texte dans les boutons */
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}


/* Si tu as des indicateurs de pagination, tu peux ajouter du style ici */
.mon-carrousel-pagination {
  display: flex;
  justify-content: center;
  /* Centre les dots horizontalement */
  padding: 10px 0;
}

.mon-carrousel-pagination button {
  background: none;
  border: none;
}

.mon-carrousel-pagination button.active {
  background-color: #333;
}

.mon-carrousel-pagination .dot {
  background: none;
  border: 1px solid rgb(255, 255, 255);
  /* Donne une bordure aux boutons pour les faire ressembler à des dots */
  display: inline-block;
  width: 15px;
  /* La taille de tes boutons */
  height: 15px;
  /* La taille de tes boutons */
  margin: 0 5px;
  border-radius: 50%;
  /* Les rend circulaires */
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.mon-carrousel-pagination .dot.active {
  background-color: #909090;
  /* Couleur pour l'indicateur actif */
}