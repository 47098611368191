.footer {
  background-color: #000000;
  color: white;
  padding: 20px 0;
}

.footer-logo {
    text-align: left; 
    padding-left: 80px;
    margin-bottom: 20px;
  }
  
.footer-logo img {
  max-width: 200px; 
}

.footer-content {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.footer-section h4 {
  margin-bottom: 15px;
}

.footer-section ul {
  list-style-type: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 10px;
}

.footer-bottom {
  text-align: center;
  padding-top: 10px;
  border-top: 1px solid #444;
}

/* Footer.css */
/* ... Autres styles de footer ... */



.footer-section ul li a {
  color: #fff; /* Couleur initiale des liens */
  text-decoration: none; /* Enlève le soulignement */
  transition: color 0.3s; /* Transition douce pour le changement de couleur */
}

.footer-section ul li a:hover {
  color: #ffffff; /* Couleur des liens au survol */
}

.social-media-icons a .svg-inline--fa {
  color: rgb(255, 255, 255);
  font-size: 40px;
  padding-right: 20px;
}
