.contact-form {
    max-width: 80%;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
    font-size:1.2rem;
  }
  
  .contact-form input,
  .contact-form textarea,
  .contact-form select,
  .contact-form button {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .textarea{
    width: 440px;
    height: 150px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  }
  
  .contact-form button {
    background-color: #003c6a;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .contact-form button:hover {
    background-color: #000000;
  }
  